import React, { useContext } from "react"
import { PageLayout, PageTitle } from "../components"
import { Container, Image } from "react-bootstrap"
import { Link, graphql } from "gatsby"
import { ThemeContext, SEO } from "../utils"

export default ({ data }) => {
  const MediaLink = ({ title, author, link }) => (
    <li key={title} style={{ color: "gray" }}>
      <a rel="noopener noreferrer" href={link}>
        {title}
      </a>
      &nbsp;-<i>{author}</i>
    </li>
  )

  const {
    author,
    occupation,
    readingList,
    showsList,
    designations,
    unemployed,
  } = data.site.siteMetadata
  const { toString } = useContext(ThemeContext)

  const bookLinks = readingList.map(book => MediaLink(book))
  const showLinks = showsList.map(show => MediaLink(show))

  return (
    <PageLayout>
      <SEO title="About Me" />
      <PageTitle title="About Me" />
      <Container>
        <Image
          rounded
          width="140"
          height="140"
          src={`../../icons/mypic-${toString()}.png`}
          alt={author}
        />
        <article className="w-75 m-auto pt-2">
          <p className="text-center">
            {designations.map((attr, i) => (
              <span key={attr}>
                &nbsp;<b>{attr}</b>&nbsp;
                {i < designations.length - 1 && <>||</>}
              </span>
            ))}
          </p>
          <p className="i-5 mt-4 pt-2 text-center">
            Hello there! My name is <b>{`${author}`}</b>. I am a Computer Science student
            at &nbsp;
            <a
              href="https://www.gatech.edu/"
              target="_blank"
              rel="noopener noreferrer"
            >
            Georgia Institute of Technology.
            </a> &nbsp;
            As a second-year student, I am taking a concentration in &nbsp;
            <a
              href="https://www.cc.gatech.edu/intelligence"
              target="_blank"
              rel="noopener noreferrer"
            >
              Intelligence
            </a>
              &nbsp; and &nbsp;
            <a
              href="https://www.cc.gatech.edu/content/information-internetworks"
              target="_blank"
              rel="noopener noreferrer"
            >
              Information Internetworks
            </a>. I first became interested in programming after taking an AP Computer Science
              class in high school. The late nights of exploring the challenging problems that
              my teacher would give me, and eventually solving them after many iterations, made
              me realize my love for programming. This hunt for challenging problems and the thrill
              of finding the solution is the root of my interest in Computer Science. But, I am also
              motivated to work on projects where I can contribute to making an impact on the customers
              who use the product. I find it fascinating that I have the ability to create something that
              can be used to improve people's daily lives! 
          </p>
          <p className="i-5 text-center">
            In my spare time, I like to work on programming projects that seem 
            challenging, just to see if I can do it. But I also try to build projects
            that I feel would be useful for myself or others. Besides programming, I like to
            watch the NBA and play basketball with a couple of friends. I am also an avid 
            fan of tennis and am always looking for a new person to challenge to a match!
          </p>
          <p className="i-5 text-center">
            Check out my <Link to="/projects">projects</Link> to see what I've
            been up to! Thank you for taking the time to read this :)
          </p>
        </article>
        <article className="w-75 m-auto">
          {unemployed && (
            <>
              <hr />
              <p className="unemployed">
                <small>
                  I am <b>currently looking for new opportunities</b>! If you
                  like what you <Link to="/resume">see</Link>, let's get
                  in&nbsp;
                  <a
                    href="mailto:baralasim6@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    touch
                  </a>
                  !
                </small>
              </p>
            </>
          )}
          <hr />
          {/* <h5 className="watch-list-title pt-4">
            Here are a couple of books from my reading list:
          </h5>
          <ul style={{ fontSize: "0.9rem", listStyle: "none" }}>{bookLinks}</ul>
          <h5 className="watch-list-title pt-4">
            Here are a couple of shows from my watch list:
          </h5>
          <ul style={{ fontSize: "0.9rem", listStyle: "none" }}>{showLinks}</ul>
          <h5 className="watch-list-title pt-4">
            Here are a couple of movies from my watch list:
          </h5>
          <p>
            <i>...waaaay too many to list.</i>
          </p> */}
        </article>
      </Container>
    </PageLayout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        unemployed
        occupation
        author
        designations
        readingList {
          title
          author
          link
        }
        showsList {
          title
          author
          link
        }
      }
    }
  }
`
